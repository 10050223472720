// Import google font: Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Colors Variables
$white: #ffffff;
$black: #000000;
// $bg1: #17082A;
// $bg2: #210F37;
$bg1: #080f2a;
$bg2: #100f37;

$orange: #F79E44;
$pink: #E71D8B;
$ltpink: #ECBBDA;
$purple: #6644B8;
$purple2: #372562;
$ltpurple: #BCB7C3;

$yellow: #f4f744;
$blue: #022959;
$ltblue: #046b99;
$navyblue:  #011d4c;
$navyblue2:  #01002d;
$ltnavyblue: #023a98;

$imagePosters: "https://cdn.shopify.com/s/files/1/0607/5056/1539/articles/netflix.jpg?v=1669877376";

// Global default
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: $white;
  background-color: $bg1;
}

// Branding Logo
.nav-logo {
  height: 50px;
  width: 150px;
  object-fit: contain;
}
.footer-logo {
  width: 150px;
  object-fit: contain;
  margin-bottom: 30px;
}

// Custom Bootstrap 
.btn.btn-primary {
  background-color: $blue;
  border-color: $blue;
}
.navbar.bg-dark {
  background-color: $bg1 !important;
}
.btn.btn-outline {
  background-color: transparent;
  border: 1px solid $blue;
}
.breadcrumb, .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: $blue;
}
.breadcrumb .breadcrumb-item.active {
  font-size: 14px;
  color: $white;
}
.breadcrumb .breadcrumb-item::before{
  color: $blue;
}