
// Soon 
.soon-banner {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 30px;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  // background: linear-gradient(to bottom, rgba(23, 8, 42, 1) 0%, rgba(23, 8, 42, 0.8) 50%, rgba(23, 8, 42, 1) 100%), url($imagePosters);
  // background: linear-gradient(to bottom, rgba(9, 8, 42, 0.625) 0%, rgba(10, 8, 42, 0.395) 50%, rgba(8, 10, 42, 0.515) 100%), url('../assets/images/background.png');
  background: url('../assets/images/background.png');
  background-size: cover;
  // background-position: 20% 30%;
  background-position: center 30%;
  // background-position: center;
  // background-position: top;
  transition: 0.5s;
  overflow: hidden;
  // border: 1px solid red;
}

@media only screen and (max-width: 600px) {
  .soon-banner {
    background-position: center;
    min-height: 120vh;
    transition: 0.5s;
  }
}

.soon-contents{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // border: 1px solid red;
}


@media only screen and (max-width: 600px) {
  .soon-contents {
    padding-top: 140px;
    // padding-bottom: 30px;
    // justify-content: center;
    // border: 1px solid red;
    transition: 0.4s;
  }
}
.soon-logo {
  width: 180px;
  height: 180px;
  object-fit: contain;
  transition: 0.4s;
}

@media only screen and (max-width: 600px) {
  .soon-logo {
    width: 130px; /* Adjust the desired width for smaller screens */
    height: 130px; /* Adjust the desired height for smaller screens */
    margin-top: 60px;
    transition: 0.4s;
  }
}
.soon-text {
  width: auto;
  height: 110px;
  border-radius: 6px;
  color: $pink;
  transition: 0.5s;
  // letter-spacing: 4px;
  // font-size: 50px;
  // height: 50px;
  // line-height: 50px;
  // padding: 0 20px;
  // border: 1px solid $pink;
  // margin-top: 20px;
  // object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .soon-text {
    width: auto; /* Adjust the desired width for smaller screens */
    height: 70px; /* Adjust the desired height for smaller screens */
    transition: 0.5s;
    // margin-top: 20px;
  }
}

.soon-loading {
  width: auto;
  height: 40px;
  border-radius: 6px;
  color: $pink;
  margin-top: 50px;
  transition: 0.4s;
  // border: 1px solid red;
}

@media only screen and (max-width: 600px) {
  .soon-loading {
    width: auto; /* Adjust the desired width for smaller screens */
    height: 30px; /* Adjust the desired height for smaller screens */
    margin-bottom: 20px;
    margin-top: 40px;
    transition: 0.4s;
  }
}

.soon-footer{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
  // border: 1px solid red;
}

.soon-socials{
  display: flex;
}

.soon-social{
  width: auto;
  height: 80px;
}

.soon-info{
  width: 800px;
  height: 140px;
  font-size: larger;
  text-align: center;
  transition: 0.5s;
}
@media only screen and (max-width: 600px) {
  .soon-info {
    width: 290px; /* Adjust the desired width for smaller screens */
    height: 100px; /* Adjust the desired height for smaller screens */
    transition: 0.5s;
  }
}

// Welcome
.welcome-banner {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 800px;
  position: relative;
  // background: linear-gradient(to bottom, rgba(23, 8, 42, 0.8) 0%, rgba(23, 8, 42, 0.6) 50%, rgba(23, 8, 42, 1) 100%), url($imagePosters);
  background: url('../assets/images/background.png');
  background-size: cover;
  background-position: 50% 50%;
}
.welcome-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.welcome-content h1 {
  font-weight: 700;
}
.welcome-content h3 {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}